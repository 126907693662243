<template>
  <b-card-code title="" no-body>
    <b-card-body>
      <div class="demo-inline-spacing">
        <b-button
          v-if="detailAnggota.orang_status == 'inactive'"
          variant="gradient-primary"
          @click="approveDataAnggota()"
        >
          <feather-icon icon="CheckIcon" class="mr-50" />
          <span class="align-middle">Setuju/Aktifkan</span>
        </b-button>
        <b-button variant="gradient-primary" @click="modalSendEmail()">
          <feather-icon icon="SendIcon" class="mr-50" />
          <span class="align-middle">Send Email</span>
        </b-button>
      </div>
    </b-card-body>

    <!-- list data calon angggota-->
    <template #modal-title>Detail Data </template>
    <!-- Invoice Description: Total -->
    <table style="width: 100%">
      <b-table-simple hover caption-top responsive class="rounded-bottom mb-0">
        <b-thead head-variant="light">
          <b-tr>
            <b-th colspan="7" class="text-right">
              #{{ detailAnggota.id }} [{{ detailAnggota.orang_status }}] [{{
                detailAnggota.orang_level
              }}]
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr>
            <b-th rowspan="20">
              Data Diri
            </b-th>
            <b-th class="text-right">
              Anggota Wilayah :
            </b-th>
            <b-td>{{ detailAnggota.orang_level_wilayah }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="text-right">
              Foto :
            </b-th>
            <b-td v-if="detailAnggota.orang_file_photo_resmi != null">
              <img
                v-if="!detailAnggota.orang_file_photo_resmi.includes('https')"
                :src="
                  'http://www.idijakpus.or.id/uploads/reg/pasphoto/' +
                    detailAnggota.id +
                    '/' +
                    detailAnggota.orang_file_photo_resmi
                "
                alt="foto"
                width="200"
                height="300"
              />
              <b-td v-else>
                <img
                  :src="detailAnggota.orang_file_photo_resmi"
                  alt="foto"
                  width="200"
                  height="300"
                />
              </b-td>
            </b-td>
            <b-td v-else>{{ "foto tidak ada" }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="text-right">
              NPA :
            </b-th>
            <b-td> {{ detailAnggota.orang_npa_idi }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="text-right">
              Masa Berlaku NPA :
            </b-th>
            <b-td> {{ detailAnggota.orang_npa_masa_berlaku }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="text-right">
              File KTA :
            </b-th>
            <b-td v-if="detailAnggota.orang_npa_file != null">
              <a
                v-if="!detailAnggota.orang_npa_file.includes('https')"
                :href="
                  'http://www.idijakpus.or.id/uploads/reg/orang_npa_file/' +
                    detailAnggota.id +
                    '/' +
                    detailAnggota.orang_npa_file
                "
                alt="pdf"
                target="_blank"
                >Tampilkan File</a
              >
              <a
                v-else
                :href="detailAnggota.orang_npa_file"
                alt="pdf"
                target="_blank"
                >Lihat File NPA</a
              >
            </b-td>
            <b-td v-else>{{ "file tidak ada" }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="text-right">
              Nama Lengkap Tanpa Gelar :
            </b-th>
            <b-td> {{ detailAnggota.orang_nama_lengkap }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="text-right">
              Gelar Depan :
            </b-th>
            <b-td> {{ detailAnggota.orang_gelar_depan }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="text-right">
              Gelar Belakang :
            </b-th>
            <b-td> {{ detailAnggota.orang_gelar_belakang }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="text-right">
              Tempat Lahir :
            </b-th>
            <b-td> {{ detailAnggota.orang_tempat_lahir }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="text-right">
              Tanggal Lahir :
            </b-th>
            <b-td> {{ detailAnggota.orang_tanggal_lahir }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="text-right">
              Jenis Kelamin :
            </b-th>
            <b-td> {{ detailAnggota.orang_jenis_kelamin }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="text-right">
              Jenis Kartu Identitas :
            </b-th>
            <b-td> {{ detailAnggota.orang_kartu_id_jenis }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="text-right">
              Nomor Identitas :
            </b-th>
            <b-td> {{ detailAnggota.orang_kartu_id_nomor }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="text-right">
              File Identitas :
            </b-th>
            <b-td v-if="detailAnggota.orang_kartu_id_file != null">
              <a
                v-if="!detailAnggota.orang_kartu_id_file.includes('https')"
                :href="
                  'http://www.idijakpus.or.id/uploads/reg/orang_kartu_id_file/' +
                    detailAnggota.id +
                    '/' +
                    detailAnggota.orang_kartu_id_file
                "
                alt="pdf"
                target="_blank"
                >Tampilkan File</a
              >
              <a
                v-else
                :href="detailAnggota.orang_kartu_id_file"
                alt="pdf"
                target="_blank"
                >Lihat File Identitas</a
              >
            </b-td>
            <b-td v-else>{{ "file tidak ada" }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="text-right">
              Status Pernikahan :
            </b-th>
            <b-td> {{ detailAnggota.orang_pernikahan_status }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="text-right">
              Nama Pasangan :
            </b-th>
            <b-td> {{ detailAnggota.orang_pernikahan_nama_pasangan }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="text-right">
              Alamat Tempat Tinggal :
            </b-th>
            <b-td> {{ detailAnggota.orang_alamat_rumah }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="text-right">
              Telpon :
            </b-th>
            <b-td> {{ detailAnggota.orang_nomor_telpon }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="text-right">
              Handphone :
            </b-th>
            <b-td> {{ detailAnggota.orang_nomor_hp }}</b-td>
          </b-tr>
          <b-tr>
            <b-th class="text-right">
              Email :
            </b-th>
            <b-td> {{ detailAnggota.orang_email }}</b-td>
          </b-tr>
        </b-tbody>
        <b-tfoot>
          <b-tr>
            <b-td colspan="7" variant="secondary" class="text-right"> </b-td>
          </b-tr>
        </b-tfoot>
      </b-table-simple>
    </table>

    <!-- modal send message email -->
    <b-modal
      id="modal-send-email"
      title="Send Email"
      size="xl"
      cancel-variant="outline-secondary"
      hide-footer
    >
      <b-form @submit.prevent>
        <b-form-group label="Email" label-for="Email">
          <b-form-input
            id="email"
            v-model="detailAnggota.orang_email"
            placeholder="Masukan Email"
            readonly
          />
        </b-form-group>
        <b-form-group label="Subject" label-for="Subject">
          <validation-provider #default="{ errors }" name="Subject">
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                id="email"
                v-model="sendemail.subject"
                placeholder="Masukan Subject Email"
                class="form-control-merge"
                :state="errors.length > 0 ? false : null"
                name="login-password"
                autocomplete="on"
              />
            </b-input-group>
          </validation-provider>
        </b-form-group>
        <b-form-group label="Message" label-for="Message">
          <validation-provider #default="{ errors }" name="Subject">
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <quill-editor
                v-model="sendemail.message"
                :options="snowOptions"
              />
            </b-input-group>
          </validation-provider>
        </b-form-group>
        <br />
        <b-button
          variant="primary"
          type="submit"
          block
          :disabled="invalid"
          @click="sendMessageEmail"
        >
          Kirim
        </b-button>
      </b-form>
    </b-modal>
  </b-card-code>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BTable,
  BButton,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BFormDatepicker,
  BTfoot,
  BFormGroup,
  BAvatar,
  BBadge,
  BCardBody,
  BPagination,
  BFormTextarea,
  BButtonToolbar,
  BButtonGroup,
  BDropdown,
  BDropdownItem,
  BFormInput,
  BRow,
  BCard,
  BCardText,
  BCardTitle,
  BCol,
  BModal,
  BFormFile,
} from "bootstrap-vue";
import API from "@/api";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { quillEditor } from "vue-quill-editor";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    BCardCode,
    BTable,
    BButton,
    BAvatar,
    BFormFile,
    BCardBody,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
    BBadge,
    vSelect,
    BFormDatepicker,
    BPagination,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BFormGroup,
    BCard,
    BCardText,
    BCardTitle,
    BRow,
    ValidationProvider,
    ValidationObserver,
    BCol,
    BModal,
    BFormTextarea,
    quillEditor,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      currentPage: 1,
      rows: 0,
      perPage: 20,
      modes: ["multi", "single", "range"],

      //list anggota
      fields: [
        { key: "id", label: "id" },
        [{ key: "orang_nama_lengkap", label: "nama lengkap" }],
        [{ key: "orang_npa_idi", label: "NPA" }],
        [{ key: "orang_email", label: "email" }],
        [{ key: "orang_tempat_lahir", label: "tempat lahir" }],
        [{ key: "orang_tanggal_lahir", label: "tanggal lahir" }],
        [{ key: "orang_level_wilayah", label: "level" }],
        [{ key: "orang_nomor_hp", label: "nomor handphone" }],
        [{ key: "orang_status", label: "status" }],
        [{ key: "orang_npa_masa_berlaku", label: "npa masa berlaku" }],
        [{ key: "ppds.reksip_kategori", label: "ppds" }],
        "action",
      ],
      items: [],

      detailAnggota: {},

      sendemail: {
        subject: "",
        message: "",
      },
      status: [
        {
          0: "Register",
          1: "Verify",
          2: "Can't find",
          3: "Input wrong data",
        },
        {
          0: "light-primary",
          1: "light-success",
          2: "light-warning",
          3: "light-warning",
          5: "light-info",
        },
      ],
      selectMode: "multi",
      selected: [],

      solidColor: [{ bg: "danger", title: "Danger card title" }],

      snowOptions: {
        theme: "snow",
      },
    };
  },

  computed: {},
  methods: {
    modalSendEmail() {
      this.$bvModal.show("modal-send-email");
    },

    async getDetailAnggota() {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);

        const { data } = await API.anggota.detail(
          this.$route.params.anggota_id //fetch data anggota by id(sesuai dengan id anggota)
        );
        this.detailAnggota = data; //simpan detail data anggota in property

        this.handleMsgSuccess("Berhasil Menampilkan Detail Anggota");
      } catch (error) {
        this.handleMsgErrors(error, "Terjadi kesalahan silahkan coba lagi!");
      } finally {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },

    async approveDataAnggota() {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);
        this.$swal({
          title: "Apakah kamu yakin?",
          text:
            "Data User ini akan di approve, pastikan data yang di approve sudah memenuhi persyaratan",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Ya, Approve!",
          cancelButtonText: "Batal",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        })
          .then((result) => {
            this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);
            if (result.value) {
              return API.anggota.approveUser(this.detailAnggota.orang_npa_idi);
            }
            return false;
          })
          .then((result) => {
            if (result) {
              this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
              this.$toast({
                component: ToastificationContentVue,
                props: {
                  title: "Berhasil Approve User",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
            }
            this.refreshPage();
          })
          .catch((error) => {
            this.handleMsgErrors(error, "terjadi kesalahan silahkan coba lagi");
          })
          .finally(() => {
            this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
          });
      } catch (error) {
        this.handleMsgErrors(error, "Terjadi kesalahan silahkan coba lagi!");
      } finally {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },

    async sendMessageEmail() {
      try {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);
        this.$swal({
          title: "Apakah kamu yakin?",
          text:
            "Data message ini akan terkirim ke email users, pastikan email,subject dan message yang di isi sudah benar",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Ya, Kirim!",
          cancelButtonText: "Batal",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        })
          .then((result) => {
            this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", true);
            if (result.value) {
              return API.anggota.sendEmail({
                email: this.detailAnggota.orang_email,
                subject: this.sendemail.subject,
                message: this.sendemail.message,
              });
            }
            return false;
          })
          .then((result) => {
            if (result) {
              this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
              this.$toast({
                component: ToastificationContentVue,
                props: {
                  title: "Berhasil Kirim Pesan",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
            }
            this.refreshPage();
          })
          .catch((error) => {
            this.handleMsgErrors(error, "terjadi kesalahan silahkan coba lagi");
          })
          .finally(() => {
            this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
          });
      } catch (error) {
        this.handleMsgErrors(error, "Terjadi kesalahan silahkan coba lagi!");
      } finally {
        this.$store.commit("SET_IS_LOADING_FOR_SUBMIT", false);
      }
    },
  },
  mounted() {
    this.getDetailAnggota();
  },
};
</script>

<style lang="scss">
.b-table-selectable {
  .feather {
    font-size: 1.3rem;
  }
}
</style>
